<template>
  <div class="container-fluid top-menos">
    <loading-overlay
      :active="loading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="$t('label.ErrorMessage')"
      color="dark"
      size="xl"
      class="modal-extended"
      @update:show="closeModal()"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
    >
      <CRow>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            size="lg"
            :fields="fields"
            :items="ErorrList"
            :noItemsView="tableText.noItemsViewText"
          >
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #footer>
        <CButton color="wipe" @click="closeModal()">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import GeneralMixin from '@/_mixins/general';

function data() {
  return {
    modalActive: false,
    loading: false,
    ErorrList: [],
  };
}

function closeModal() {
  this.$emit('close-modal');
}

function fields(){
  return [
    { label: this.$t('label.ErrorMessage'), key: 'Error', _classes: 'text-center', _style: 'width:100%;', filter: false},
  ];
}

export default {
  name: 'modal-error',
  props: { modal: Boolean, itemError: String },
  data,
  mixins: [
    GeneralMixin, 
  ],
  methods: {
    closeModal,
  },
  computed: {
    fields,
  },
  watch: {
    modal: function(val) {
      this.modalActive = val;
      if (val) {
        this.ErorrList.push({
          Error: this.itemError?this.itemError:'',
        })
      }else{
        this.ErorrList = [];
      }
    },
  },
  
};
</script>